const config = {
  "broker": "3",
  "name": "Spanish",
  "registrationBundle": "spanisheu",
  "defaultCountryName": "Spain",
  "defaultCountryCode": "ES",
  "fxgeoip_code": "ES",
  languageCode: 'es',
  languageName:'Spanish',
  spokenLanguageCode:'es',
  direction:'ltr',
  footerDisclaimer:'894',
  brand: 'iforex',
  content_lang: 'es_eu'
};
module.exports = config;